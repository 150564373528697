import React from 'react'
import Router from 'next/router'
import { connect } from 'react-redux'

import Header from '../components/public/shared/Header'
import LoginForm from '../components/public/auth/LoginForm'
import { authenticate, reauthenticate } from '../redux/actions/authentication'
import { isAuthenticated, login } from '../utils/auth'
import SurfConextAuthButton from '../components/public/surf-conext/SurfConextAuthButton'

interface LoginProps {
	dispatch: any
	authenticated: boolean
	url: string
	path: string
	conextUrl: string
	token: string
	success: string
	basePath: string
}

const basePath = process.env.BASE_PATH
const SURF_CONEXT_URL = process.env.SURFCONEXT_LOGIN_URL

export const getServerSideProps = (ctx) => {
	return {
		props: {
			authenticated: isAuthenticated(ctx),
			title: 'Login',
			url: `${basePath}/login`,
			path: ctx.resolvedUrl,
			basePath: basePath,
			conextUrl: SURF_CONEXT_URL,
			token: (ctx.query && ctx.query.token) || null,
			success: (ctx.query && ctx.query.success) || null,
		},
	}
}

// Port in to using useState hooks, if you need state
class Login extends React.Component<
	LoginProps,
	{ loading: boolean; error: string }
> {
	constructor(props: any) {
		super(props)

		this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
		this.state = {
			loading: false,
			error: '',
		}
	}

	async componentDidMount() {
		const { authenticated, token, success, dispatch } = this.props

		const query = Router.query

		if (authenticated) {
			Router.push('/account')
		}

		if (token && success === '1') {
			this.setState({ loading: true })
			login(token)
			await dispatch(reauthenticate(token))
			if (query.redirect) {
				Router.push(query.redirect.toString())
			} else {
				Router.push('/account')
			}
		}
		/**
		 * When logging in via surfconext fails (BE throws a redirect with success = false)
		 * there can be 2 scenarios:
		 * 1. The user that tried to login is not marked as a 'student' in Surfconext, therefore cannot login using this feature
		 * 2. The user is a student but has not signed up yet for a space using the /challenge-spaces/{id}/user-registration link
		 */
		if (
			query.action &&
			query.action === 'surfconext' &&
			query.success === 'false'
		) {
			this.setState({ error: 'Surfconext error' })
		}
	}

	handleLoginSubmit(formData: any) {
		this.setState({ loading: true, error: '' })

		const query = Router.query

		// Redirect to intended route if exists
		this.props
			.dispatch(authenticate(formData))
			.then(() => {
				if (query.redirect) {
					Router.push(query.redirect.toString())
				} else {
					Router.push('/account')
				}
			})
			.catch((error) => {
				const errorText = error.message ? error.message : error
				this.setState({ error: errorText })
			})
	}

	render() {
		const { authenticated, basePath } = this.props

		return (
			<div className="wrapper bg-white min-h-screen">
				<Header authenticated={authenticated} />
				<main className="pt-20">
					<div className="relative py-10">
						<div className="container mx-auto px-4 relative z-10">
							<div className="max-w-lg bg-white mx-auto rounded-lg overflow-hidden pt-6">
								<h1 className="font-sans font-bold text-3xl mb-3 px-8 py-4 text-center">
									Login
								</h1>

								<div className="px-8 pt-6 pb-8 mb-4">
									<LoginForm
										onFormSubmit={this.handleLoginSubmit}
										error={this.state.error}
									/>
									<span className="relative block border-b my-8 w-full text-center">
										<span className="transform absolute font-semibold -bottom-3 left-1/2 -translate-x-1/2 text-gray-500 px-2 block bg-white">
											OR
										</span>
									</span>
									{this.state.error === 'Surfconext error' && (
										<p className="text-red-600 text-sm mb-2">
											It seems you&apos;re not a student or you have not
											registered for a challenge space yet. Please contact your
											lecturer.
										</p>
									)}
									<SurfConextAuthButton
										authUrl={`${
											this.props.conextUrl
										}?redirect=${encodeURIComponent(
											`${basePath}${this.props.path}?action=surfconext`
										)}`}
										text={'Login with your student institution account*'}
									/>
									<span className="text-xs -mt-1 text-center w-full block text-gray-500">
										* Students only
									</span>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default connect()(Login)
